@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@800&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
* {
  font-family: "Manrope", sans-serif;
}
:root {
  --color-primary: #240b3b;
  --color-secondary: #c3bcd4;
  --color-text: #222222;
  --color-gray1: #454b4c;
  --color-red1: #f55a00;
  --color-blue1: #3865f3;
  --color-lightgray: #94959b;
  --color-bg-lightgray: #f6f6f6;
  --color-border-gray: #eaeaea;
  --color-dark-green: #126d62;

  --common-height: 60px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
}

#root {
  width: 100% !important;
  overflow-y: hidden;
  position: relative;
}

.hidden {
  display: none;
}

.flex_1 {
  flex: 1;
}

.flex_wrap {
  flex-wrap: wrap;
}

.pos_relative {
  position: relative;
}

.row_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center-align {
  display: flex;
  justify-content: center;
}

.align-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-col-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align-col-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.align-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.align-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.align-end {
  display: flex;
  justify-content: flex-end;
}

.row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.bg-cover {
  background-repeat: no-repeat !important;
  background-position: 50% !important;
  background-size: cover !important;
}

.overlay-dark {
  transition: all 0.2s ease;
}

.overlay-dark:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  /*opacity: .2;*/
  /*background-color: black;*/
}

.overlay-dark > *,
.overlay-light > * {
  z-index: 1;
  position: relative;
}

.btn-style {
  cursor: pointer;
}

.hide-sm {
  display: inherit;
}

.sm-100 {
  width: inherit;
}

.sm-flex-100 {
  flex: 1;
}

.md-flex-100 {
  flex: 1;
}

.max-lg {
  max-width: 1600px;
}

.color-dark {
  color: var(--color-text);
}
.color-light {
  color: white !important;
}
.color-primary {
  color: var(--color-primary);
}
.color-gray {
  color: var(--color-lightgray);
}

.bg-primary {
  background-color: var(--color-primary);
}
.bg-primary-light {
  background: rgba(56, 101, 243, 0.15);
}
.bg-dark-green {
  background-color: var(--color-dark-green);
}

.rounded-4xl {
  border-radius: 32px;
}

.text-40 {
  font-size: 40px;
}

.full-width {
  width: 100%;
}
.full-width {
  width: 100%;
}

button,
.btn {
  height: 53px;
  text-align: center;
  padding: 0 20px;
  border-radius: 12px;
  font-weight: 600;
}
button.primary,
.btn.primary {
  background-color: var(--color-primary);
  color: white;
}

.border-color-primary {
  border-color: var(--color-border-gray);
}

.ant-switch {
  background: rgba(0, 0, 0, 0.25) !important;
}

.ant-switch.ant-switch-checked {
  background: #1677ff !important;
}

@media (max-width: 768px) {
  .md-flex-100 {
    flex: none;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .hide-sm {
    display: none;
  }
  .sm-100 {
    width: 100%;
  }
  .sm-flex-100 {
    flex: none;
    width: 100%;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: #240b3b !important ;
  background-color: #240b3b !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #240b3b;
  background-color: #240b3b !important;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: var(--color-primary) !important;
}
.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: var(--color-primary) !important;
}
.ant-picker-dropdown .ant-picker-today-btn {
  color: var(--color-text) !important;
}
.searchBtn{
  height: 55px;
    width: 55px;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
}
.searchBtn img{
  width: 25px;
  height: 25px;
  max-width: 25px;
}
@media screen and (max-width: 1023px) {
  .searchBtn{
    width: 100%;
  }  
}
.react-multi-carousel-dot button{
  border:none !important;
  background-color: #B7B7B7 !important;
}
.react-multi-carousel-dot--active button{
  background-color: #240B3B !important;
}
.backLine{
  position: relative;
}
.backLine::before{
  content: "";
    position: absolute;
    width: 100%;
    height: 8px;
    background-color: #6DDFE2;
    bottom: 8px;
    z-index: -1;
}


.homeSlider .carousel {
  position: relative;
}

.homeSlider .carousel.pointer-event {
  touch-action: pan-y;
}

.homeSlider .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  clear: both;
}

.homeSlider .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease;
}

.homeSlider .carousel-item.active,
.homeSlider .carousel-item-next,
.homeSlider .carousel-item-prev {
  display: block;
}

.homeSlider .carousel-item-next:not(.homeSlider .carousel-item-start),
.homeSlider .active.carousel-item-end {
  transform: translateX(100%);
}

.homeSlider .carousel-item-prev:not(.homeSlider .carousel-item-end),
.homeSlider .active.carousel-item-start {
  transform: translateX(-100%);
}

/* Alternate transitions */
.homeSlider .carousel-fade .homeSlider .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.homeSlider .carousel-fade .homeSlider .carousel-item.active,
.homeSlider .carousel-fade .homeSlider .carousel-item-next.homeSlider .carousel-item-start,
.homeSlider .carousel-fade .homeSlider .carousel-item-prev.homeSlider .carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.homeSlider .carousel-fade .homeSlider .active.homeSlider .carousel-item-start,
.homeSlider .carousel-fade .homeSlider .active.homeSlider .carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

/* Left/right controls for nav */
.homeSlider .carousel-control-prev,
.homeSlider .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5%;
  padding: 0;
  color: #000;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.homeSlider .carousel-control-prev:hover,
.homeSlider .carousel-control-next:hover,
.homeSlider .carousel-control-prev:focus,
.homeSlider .carousel-control-next:focus {
  color: #000;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.homeSlider .carousel-control-prev {
  left: 0;
}

.homeSlider .carousel-control-next {
  right: 0;
}

/* Icons for controls */
.homeSlider .carousel-control-prev-icon,
.homeSlider .carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.homeSlider .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.homeSlider .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}
.homeSlider .visually-hidden{
  display: none;
}
/* Optional indicator pips/controls */
.homeSlider .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 5%;
  margin-bottom: 1rem;
  margin-left: 5%;
}

.homeSlider .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  background-color: #fff;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.6s ease;
}

.homeSlider .carousel-indicators .active {
  opacity: 1;
}

/* Optional captions */
.homeSlider .carousel-caption {
  position: absolute;
  right: 50%;
  bottom: 20px;
  left: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  text-align: center;
  transform: translateX(-50%);
}

/* Dark mode carousel */
.homeSlider .carousel-dark .homeSlider .carousel-control-prev-icon,
.homeSlider .carousel-dark .homeSlider .carousel-control-next-icon {
  filter: invert(1);
}

.homeSlider .carousel-dark .homeSlider .carousel-indicators [data-bs-target] {
  background-color: #fff;
}

.homeSlider .carousel-dark .homeSlider .carousel-caption {
  color: #fff;
}

.lineClamp1{
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.lineClamp2{
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: normal;
}