.status-card {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: fit-content;
}

.subtotal-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.subtotal-wrapper span {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 165%;
  letter-spacing: 0.005em;
  color: #677489;
}

.subtotal-wrapper p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 165%;
  letter-spacing: 0.005em;
  color: #111729;
}
