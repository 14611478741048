.dropdown-opened {
  /* box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.05) */
}

.dropdown-closed {
  /* border-bottom: 2px solid var(--color-border-gray); */
}

.dropdown-layout {
  display: flex;
  border-radius: 8px;
}

.dropdown-layout p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
