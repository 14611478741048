.bybest-noti .ant-modal-content {
  padding: 0px;
}

.bybest-noti .ant-modal-close {
  display: none;
}

.register-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 0px 20px;
  cursor: pointer;
}

.register-btn div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 95.87px;
  background: #cb0000;
  border-radius: 5px;
}

.register-btn div span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

.horizontal-border {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: flex-end;
  padding: 17px 16px 16px;
  row-gap: 0px;

  border-top: 1px solid #dee2e6;
  border-radius: 0px 0px 3.8px 3.8px;
}

.offer-expires-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14.25px 0px 16px;
}

.offer-expires {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 6px;
}
