.table-column-table {
  padding: 12px 24px;
  background: #fcfcfd;
  border-bottom: 1px solid #eaecf0;
}

.table-header-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #667085;
}

.generic-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px 16px;
  border: 0px solid #eaecf0;
}

.pagination-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
