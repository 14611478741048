.register button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 13.9191px;
  gap: 10.44px;
  background: #cb0000;
  border: 0.869941px solid #cb0000;
  box-shadow: 0px 0.869941px 1.73988px rgba(0, 0, 0, 0.06);
  border-radius: 10.6827px;
  width: 100%;
  color: white;
}

.register .bybest-title-size {
  font-size: 46px !important;
}

.register .decription {
  font-size: 20px !important;
  line-height: 24px;
}

@media only screen and (max-width: 1024px) {
  .register .bybest-title-size {
    font-size: 36px !important;
  }

  .register .decription {
    font-size: 18px !important;
    line-height: 22px;
  }
}

@media only screen and (max-width: 768px) {
  .register .bybest-title-size {
    font-size: 28px !important;
  }

  .register .decription {
    font-size: 16px !important;
    line-height: 20px;
  }
}

.ask-pass {
  font-size: 16px;
  font-weight: bold;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-row {
  min-height: 60vh;
  padding: 24px;
  background-color: #dee2e6;
}

.main-row .card {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 30px;
  min-width: 30vw;
}

form .input-email {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

form .btn-primary {
  width: 100%;
  background: black !important;
  margin: 0 !important;
  border: none;
  padding: 10px 15px !important;
  font-size: 14px !important;
  height: auto !important;
  color: white;
  border-radius: 4px;
}

.register-wrapper {
  min-height: 70vh;
  padding: 15px;
  background-color: #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
}
