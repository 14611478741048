.bb-product .image .extras {
    text-transform: uppercase;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 15px;
    position: absolute;
    font-size: 12px;
    width: 100%;
    top: 0;
}

.bb-product .image .extras form button {
    background: transparent;
    border: none;
}

.bb-product .image:hover img:first-child {
    display: none !important;
}

.bb-product .data {
    padding-top: 15px;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
}

.bb-product .data a {
    text-decoration: none;
    color: black;
}

.bb-product .data .title {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.2;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
}

.bb-product .data .sub-title {
    font-weight: normal;
    line-height: 1.2;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
    font-size: 12px;
    font-family: "Poppins" !important;
}

.bb-product .data .sub-title font {
    font-family: "Poppins" !important;
    font-size: 12px !important;
    font-weight: normal !important;
}

.bb-product .data .sub-title p {
    margin: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
    font-size: 12px !important;
    font-family: "Poppins" !important;
    font-weight: normal !important;
}

.bb-product .data .sub-title p font {
    font-size: 12px !important;
    font-family: "Poppins" !important;
    font-weight: normal !important;
}

.bb-product .data .sub-title p font span {
    font-size: 12px !important;
    font-family: "Poppins" !important;
    font-weight: normal !important;
}

.bb-product .data .sub-title p span {
    font-size: 12px !important;
    font-family: "Poppins" !important;
    font-weight: normal !important;
}

.bb-product .data .price-details {
    font-size: 15px;
    font-family: "Poppins" !important;
    font-weight: bold;
    line-height: 1.2;
    padding-top: 5px;
}

.bb-product .data .price-details span.old-price {
    font-weight: normal;
    font-family: "Poppins" !important;
    position: relative;
}

.bb-product .data .price-details span.old-price:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid #000000;
    transform: rotate(-5deg);
}

.bb-product .data .price-details span.discount {
    font-weight: normal;
    font-family: "Poppins" !important;
    padding-left: 3px;
    padding-right: 3px;
}

.bb-product .data .price-details span.new-price {
    font-weight: bold;
    color: #CB0000;
    font-family: "Poppins" !important;
}

@media (max-width: 1200px) {
    .bb-product .data .price-details {
        font-size: 14px;
    }
}

@media (max-width: 990px) {
    .bb-product .data .price-details {
        font-size: 12px;
    }
}

@media (max-width: 550px) {
    .bb-product .data .price-details {
        font-size: 13px;
    }
}