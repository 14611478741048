.promo-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  text-transform: uppercase;
}

.promo-description {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
}

.promo-wrapper {
  display: flex;
  align-items: center;
  padding: 2px 10px;
  background: #e14848;
  border-radius: 12px;
}

.promo-wrapper span {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #dddddd;
}

/* Calendar */

.calendar-container {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.react-calendar {
  width: 100% !important;
  background: white !important;
  border: none !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__tile {
  max-width: 55px !important;
  width: 100% !important;
  height: 100% !important;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #240b3b;
  font-size: 14px;
  font-weight: 400;
  border-radius: 50%;
  aspect-ratio: 1 / 1 !important;
}

.react-calendar__tile--now {
  background: transparent !important;
}

.react-calendar__tile--active {
  background: #e14848 !important;
  color: white !important;
  border-radius: 50%;
  width: 100% !important;
  height: 100% !important;
  aspect-ratio: 1 / 1 !important;
}

.calhighlight {
  background: #240b3b !important;
  color: white !important;
  border-radius: 50%;
  width: 100% !important;
  height: 100% !important;
  aspect-ratio: 1 / 1 !important;
}

.today {
  background: transparent !important;
  color: #240b3b !important;
}

.react-calendar__navigation__label {
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #111827 !important;
}

.calendar-container {
  width: 100%;
}

.react-calendar__navigation button {
  background-color: #f0f0f0;
  padding: 8px;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

.react-calendar__navigation button:hover {
  background-color: #e0e0e0;
  padding: 6px;
}

.react-calendar__navigation__label {
  font-weight: bold;
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 4px;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

.react-calendar__navigation__label:hover {
  background-color: #e0e0e0;
  padding: 6px 10px;
}

.react-calendar__tile {
  padding: 10px 6px;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

.react-calendar__tile:hover {
  background-color: #fafafa;
  padding: 8px 5px;
}

.react-calendar__tile--now {
  background: #ffecb3;
  font-weight: bold;
}

.react-calendar__tile--active {
  background: #ffd700;
  color: white;
}
