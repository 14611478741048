.landing-page-container button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 13.9191px;
  gap: 10.44px;
  background: #cb0000;
  border: 0.869941px solid #cb0000;
  box-shadow: 0px 0.869941px 1.73988px rgba(0, 0, 0, 0.06);
  border-radius: 10.6827px;
  width: 100%;
  color: white;
}

.hero-text-1 {
  font-family: Manrope;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: left;
  color: #cb0000;
}

.hero-text-2 {
  font-family: Manrope;
  font-size: 52px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: -0.02em;
  text-align: left;
}

.hero-text-3 {
  font-family: Manrope;
  font-size: 52px;
  font-weight: 800;
  line-height: 56px;
  letter-spacing: -0.02em;
  text-align: left;
}

.hero-text-4 {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #404040;
}

.hero-btn-register {
  padding: 12px, 20px, 12px, 20px;
  background-color: #cb0000;
  border-radius: 6px;
}

.brand-image-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #404040;
}

.loyalty-section .moreYouShop {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #cb0000;
}

.loyalty-section .decription {
  max-width: 628.02px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #404040;
}

.bybest-title-size,
.bybest-title-size-40 {
  font-family: "Manrope";
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #171717;
}

.bybest-title-size {
  font-size: 48px;
}

.bybest-title-size-40 {
  font-size: 42px;
}

.bybest-footer-description {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
  max-width: 592px;
}

.loyalty-section .loyalty-level {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 24px;
  border-radius: 12px;
}

.loyalty-section .loyalty-level .round {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.loyalty-section .loyalty-level .name {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  max-width: 304px;
}

.loyalty-section .loyalty-level .title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}

.loyalty-section .loyalty-level .benefits {
  max-width: 227.57px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #1a1a1a;
}

/* Review */

.review-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 112px;
  gap: 40px;
  background: #fafafa;
}

.review-section {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 32px;
}

.review-section .block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  gap: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
}

.review-section .text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #171717;
}

.review-section .author-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.review-section .author-section .author {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #171717;
}

.review-section .author-section .author-role {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
}

.google-rating {
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
}

.register-member {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20.8786px 27.8381px 27.8381px;
  gap: 27.84px;
  background: #fcfcfc;
  border: 0.869941px solid #e4e4e7;
  box-shadow: 0px 1.5224px 3.47976px -0.869941px rgba(0, 0, 0, 0.14);
  border-radius: 10.4393px;
}

.register-member .title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 26.0982px;
  line-height: 37px;
  color: #1a1a1a;
}

.register-member .description {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 15.6589px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: rgba(26, 26, 26, 0.7);
}

.register-member input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12.1792px 13.9191px;
  gap: 10.44px;
  background: #ffffff;
  border: 0.869941px solid #e4e4e7;
  box-shadow: 0px 0.869941px 2.60982px rgba(0, 0, 0, 0.1);
  border-radius: 10.6827px;
}

.register-member input ::placeholder,
.register-member select option span {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 15.6589px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgba(26, 26, 26, 0.7);
}

.register-member .label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 13.9191px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: rgba(26, 26, 26, 0.7);
  cursor: pointer;
}

.register-member .name {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 15.6589px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: rgba(26, 26, 26, 0.7);
}

.bybest-title {
  font-family: "Manrope";
  font-style: normal;
  font-size: 32px;
  line-height: 38px;
  color: #1a1a1a;
}

.bybest-title-header,
.bybest-title-faq,
.bybest-title-reviews {
  font-family: "Manrope";
  font-style: normal;
  font-size: 32px;
  line-height: 38px;
  color: #1a1a1a;
}

.register-member .benefits-title {
  font-family: "Manrope";
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
  max-width: 400px;
}

/* FAQ */

.faq-section {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 16px 16px;
  gap: 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
}

.faq-section .description,
.small-label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
}

.faq-section .title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 24px;
  color: #171717;
}

@media only screen and (max-width: 768px) {
  .review-wrapper {
    padding: 10px;
  }

  .hero-text-1 {
    font-size: 16px;
    line-height: 18px;
  }

  .hero-text-2 {
    font-size: 26px;
    line-height: 20px;
  }

  .hero-text-3 {
    font-size: 26px;
    line-height: 36px;
  }

  .hero-text-4 {
    font-size: 18px;
    line-height: 24px;
  }

  .brand-image-text {
    font-size: 16px;
    line-height: 24px;
  }

  .loyalty-section .moreYouShop {
    font-size: 16px;
    line-height: 18px;
  }

  .loyalty-section .decription {
    font-size: 16px;
    line-height: 24px;
  }

  .bybest-title-size,
  .bybest-title-size-40 {
    font-size: 26px !important;
    line-height: 32px;
  }

  .bybest-title {
    font-size: 20px !important;
    line-height: 28px;
  }

  .bybest-title-header,
  .bybest-title-faq,
  .bybest-title-reviews {
    font-size: 26px !important;
    line-height: 28px;
  }

  .bybest-footer-description {
    font-size: 15px;
    line-height: 18px;
  }

  .register-member .title {
    font-size: 22px;
    line-height: 30px;
  }

  .register-member .description {
    font-size: 14px;
    line-height: 22px;
  }

  .faq-section .description,
  .small-label {
    font-size: 15px;
    line-height: 18px;
  }

  .faq-section .title {
    font-size: 16px;
    line-height: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .landing-page-container button {
    padding: 12px 20px;
    width: auto;
    max-width: 280px;
    font-size: 16px;
  }

  .hero-text-1 {
    font-size: 18px;
    line-height: 22px;
  }

  .hero-text-2,
  .hero-text-3 {
    font-size: 42px;
    line-height: 48px;
  }

  .hero-text-4 {
    font-size: 20px;
    line-height: 30px;
  }

  .hero-btn-register {
    padding: 14px 24px;
    font-size: 18px;
  }

  .brand-image-text,
  .loyalty-section .moreYouShop,
  .loyalty-section .decription {
    font-size: 17px;
    line-height: 26px;
    text-align: left; /* Ensuring it fits well within the screen */
  }

  .bybest-title-size {
    font-size: 36px;
    line-height: 44px;
  }

  .bybest-title-size-40 {
    font-size: 34px;
    line-height: 42px;
  }

  .bybest-footer-description {
    font-size: 16px;
    line-height: 24px;
  }

  .review-wrapper {
    padding: 0px;
  }

  .review-section .block {
    padding: 20px;
    gap: 16px;
  }

  .register-member {
    padding: 20px;
    gap: 20px;
  }

  .register-member .title {
    font-size: 24px;
    line-height: 32px;
  }

  .register-member .description {
    font-size: 16px;
    line-height: 22px;
  }

  .faq-section {
    padding: 24px 16px;
    gap: 16px;
  }

  .faq-section .title {
    font-size: 18px;
    line-height: 26px;
  }

  .faq-section .description,
  .small-label {
    font-size: 15px;
    line-height: 22px;
  }
}
