.bbcart-stepper-anchorright {
  border-left-width: 24px;
  border-top-width: 24px;
  border-bottom-width: 24px;
  border-right-width: 0px;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.back-link {
  color: black;
  text-decoration: none;
  font-size: 14px;
}
