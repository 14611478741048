.login-security {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px;
  gap: 20px;
  border-left: 1px solid #e5e7eb;
}

.login-security .activities {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #373839;
}

.login-security .login-item {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #6b7280;
}
