.bybest-checkoutmodal .ant-modal-content {
  padding: 0px;
}

.bybest-checkoutmodal .ant-modal-close {
  display: none;
}

.bb-checkout-phone .flag-dropdown {
  border-color: #595959 !important;
}

.form-input {
}
