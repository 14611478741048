.platform-card {
  width: 100%;
  border-radius: 5px;
  background-color: #e4e9eb;
  padding: 30px;
  text-align: center;
  margin-bottom: 15px;
}

.platform-card .download-app {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 15px 20px;
  background: black;
  border-radius: 5px;
  min-width: 200px;
}

.platform-card .text {
  line-height: 1.2;
  padding-left: 10px;
  text-align: left;
}

.platform-card .small-text {
  color: white !important;
  font-size: 12px;
  font-weight: 300;
}

.platform-card .large-text {
  color: white !important;
  font-size: 16px;
  font-weight: 600;
}
