.brand-card {
  margin-bottom: 20px;
}

.brand-card-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
}

.brand-card-inner .icon {
  padding: 25px;
  height: 100px;
  width: 100%;
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.brand-card-inner img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.brand-card-inner .name {
  font-size: 14px;
  padding: 10px;
  width: 100%;
  text-align: center;
  background: #f2f2f2;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 700;
}
