.wishlist-item {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #c1c1c1 !important;
  border-radius: 10px;
  padding: 10px;
}

.wishlist-item .price {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #131118;
}

.wishlist-item .image {
  background: #f7f7f7;
  border-radius: 9.83206px;
  max-width: 152px;
}

.wishlist-item .image img {
  max-width: 152px;
  max-height: 208px;
}

.wishlist-item .h3 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 21px;
  color: #131118;
}

.wishlist-details p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 13.7649px;
  line-height: 19px;
  text-transform: uppercase;
  color: #131118;
}

.wishlist-item .button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  height: 45.55px;
  max-width: 280px;
  background: #131118;
  border-radius: 10px;
}

.wishlist-item .button button span {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
}

.wishlist-item .description p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 13.7649px;
  line-height: 19px;
  text-transform: uppercase;
  color: #131118;
}

.wishlist-item .description h3 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 21px;
  color: #131118;
}

@media (max-width: 768px) {
  .wishlist-item {
    padding: 4px;
  }
}
