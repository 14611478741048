.brand-icon {
  max-width: 190px;
  max-height: 40px;
}

.icons {
  padding-top: 12px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 990px) {
  .brand-icon {
    max-width: 85% !important;
  }
}
