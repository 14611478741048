.sidebar {
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;
  gap: 32px;
  background: #ffffff;
  border: 2px #efefef solid;
}

.sidebar .user-profile {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 12px;
}

.user-profile .user-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
}

.user-profile .user-name h1 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #09090a;
}

.user-profile .user-name p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #4b5768;
}

.platinium-tier {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  background: #f5f5f5;
  border-radius: 16px;
}

.platinium-tier div {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 12px;
}

.platinium-tier div span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #2a2a2e;
}

.sidebar-item-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;
}

.sidebar-item-list .sidebar-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;

  border-radius: 8px;
}

.sidebar-item-list .sidebar-item div {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 16px;
}

.sidebar-item-active {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  background: #e14848;
  border-radius: 17px;
  width: 100%;
}

.sidebar-item-active p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
}
