.details img {
  min-width: 210px;
  height: 220px;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
}

.details button {
  font-size: 18px;
  padding: 7px 9px;
  color: #0b1362;
  border: 1px solid;
  border-radius: 5px;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-select .options {
  font-size: 13px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  gap: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.product-select option {
  -webkit-appearance: none;
}

.product-select .options select {
  min-width: 55px;
  padding: 0 5px;
  border-radius: 3px;
  border: 1px solid #333;
  background: transparent;
}

@media (max-width: 767px) {
  .details img {
    width: 120px !important;
    height: 160px !important;
    min-width: 120px !important;
  }
}
