.wallet-icon-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 48px;
  height: 48px;
  background: #cb0000;
  border-radius: 6px;
}

.cash-delivery h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 165%;
  letter-spacing: 0.005em;
  color: #111729;
}

.cash-delivery span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 165%;
  letter-spacing: 0.005em;
  color: #677489;
}

.cash-delivery .button {
  border: 1px #677489 solid;
  border-radius: 12px;
  padding: 8px;
}
