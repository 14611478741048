.options {
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
  flex-direction: column;
  border: 1px;
}

.breadcrumbs {
  padding: 40px 10px;
  text-transform: uppercase;
  font-size: 16px;
}

.options .option-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
}

.options .option-boxes {
  grid-gap: 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;
}

.options .option-box.disabled {
  color: rgba(220, 53, 69, 0.3215686275);
  border: 1px solid rgba(220, 53, 69, 0.3215686275);
  cursor: not-allowed;
}

.options .option-box {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  align-items: center;
  background: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  display: flex;
  font: 600 13px / 16px proxima-nova, Arial, sans-serif;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.25px;
  padding: 12px 4px;
  text-align: center;
  transition: 0.2s ease;
}

.product-details .description {
  font-size: 13px;
  text-transform: uppercase;
}

.product-details .price {
  margin-top: 10px;
  font-size: 20px;
  display: flex;
  align-items: flex-start;
}

.product-details .discounted-price {
  font-size: 22px;
  font-weight: bold;
  color: red;
  margin-top: -4px;
}

.product-details .old-price {
  font-size: 15px;
  font-weight: bold;
  padding-left: 5px;
  position: relative;
  color: #000;
}

.product-details .percentage {
  font-size: 15px;
  font-weight: bold;
  padding-left: 5px;
  color: #000;
}

.product-details .points-info {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
}

.product-details .section-title {
  text-transform: uppercase;
  margin: 10px 0 20px 0;
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.product-details .data {
  padding-top: 15px;
  text-transform: uppercase;
  color: black;
}

.product-details .data .title {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.2;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.product-details .data .subtitle {
  font-weight: normal;
  line-height: 1.2;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
  font-size: 12px;
  font-family: "Poppins" !important;
}

.product-details .data .subtitle p {
  margin: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
  font-size: 12px !important;
  font-family: "Poppins" !important;
  font-weight: normal !important;
}

.product-details .data .price-details {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.2;
  padding-top: 5px;
}

.product-details .data .old-price {
  font-weight: normal;
  position: relative;
}

.product-details .data .price-details .new-price {
  font-weight: bold;
  color: #cb0000;
}

/* Media query */

@media (max-width: 767px) {
  .breadcrumbs {
    padding: 15px 10px !important;
    font-size: 13px !important;
    margin-top: 20px;
  }
}
