.card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.main-signin-wrapper {
  min-height: 60vh;
  background-color: #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
}
