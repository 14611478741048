.submenu-link {
  font-size: 16px !important;
  line-height: 150% !important;
  color: var(--color-text) !important;
  font-weight: 500 !important;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 10000;
}
.header-menu {
  top: 70px !important;
}

.header-menu .ant-dropdown-menu {
  padding: 20px 10px;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 50% 50%;
}

.header-menu .ant-dropdown-menu-item {
  margin: 10px 0px !important;
}

.header-menu-mobile {
  display: flex;
  visibility: visible;
  position: fixed;
  width: 100%;
  height: calc(100% - 80px);
  top: 80px;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 10000;
}

.CookieConsent {
  padding-left: 15%;
  padding-right: 15%;
}
.CookieConsent > div {
  flex: 1 !important;
}

.header-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 30px;
}

.input-search {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 8px 10px 16px;
  gap: 4px;
  background: #ffffff;
  border-bottom: 1px solid #ebebeb;
  border-radius: 10px 10px 0px 0px;
}

.input-search input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  isolation: isolate;
  height: 40px;
  background: #ffffff;
  border: 1px solid #2b2b2b;
  border-radius: 100px;
  flex: none;
  order: 0;
  flex-grow: 1;
  outline: none;
  padding-left: 40px;
}

.input-search input::placeholder {
  padding-left: 10px;
}

.input-search .search-result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 8px;
  gap: 8px;
  position: absolute;
  left: 0px;
  top: 64px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 10px 10px;
}

.search-record {
  display: flex;
  align-items: center;
  padding: 0px 8px 0px 16px;
  gap: 25px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

@media (max-width: 576px) {
  .CookieConsent {
    padding: 0px 20px;
    align-items: center !important;
  }
  .CookieConsent span {
    font-size: 12px;
  }
  .CookieConsent button {
    font-size: 12px;
    padding: 8px 16px !important;
  }
}

@media (min-width: 1024px) {
  .large-width {
    padding-inline: 10% !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .large-width {
    padding-inline: 10% !important;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-text) !important;
}

.ant-tabs-ink-bar {
  background: var(--color-text) !important;
}

.nav-item-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-item-header:hover {
  border-bottom: 5px solid #5c1c81;
  padding-top: 5px;
}

.shop-cart-circle {
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: 1px;
  right: 0px;

  background: #e14848;
}

.header-custom-boxshadow {
  box-shadow: 0px 1px 2px 0px #00000040;
}
