.footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footer-wrapper .copyright span {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.call-us-bb {
  box-sizing: border-box;
  max-height: 45px;
  background: rgba(255, 152, 0, 0.13);
  border: 1px solid #ff9800;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 8px;
  margin-top: 20px;
}

.h3-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
}

.footer-list-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #1a1e21;
}

.footer-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 0px 0px;
}
