.PI-switch.ant-switch {
  background: #ffffff !important;
  border: 1px #cb0000 solid;
}
.PI-switch .ant-switch-handle::before {
  background-color: #cb0000 !important;
  box-shadow: none !important;
}
.PI-switch.ant-switch .ant-switch-handle {
  top: 1px !important;
}

.personal-info input {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 9px 13px;
  gap: 4px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  width: 100%;
}
