.menu-header .left-side {
  padding: 0px 11px 0px 0px;
  background: white;
}

.lang {
  font-family: "Poppins";
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
}

.choose-lang,
.bold-label,
.menu-item {
  font-family: "Poppins";
  font-weight: 600;
  line-height: 16px;
  color: #000000;
  padding: 2px !important;
  margin-right: 2px;
}

.choose-lang {
  border-right: 1px solid #dee2e6;
  font-size: 12px;
}

.bold-label {
  font-size: 12px;
}

.menu-item {
  font-size: 18px;
  text-transform: uppercase;
  color: unset;
}

.my-club {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 11px 0px 10px;
  height: 11px;
  border-right: 1px solid #dee2e6;
  cursor: pointer;
}

.my-club span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #212529;
}

.image-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.header-search {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 5px 6px 25px;
  background: #ffffff;
  border-bottom: 1px solid #333333;
  position: relative;
}

.icon-absolute {
  right: 4px;
  bottom: -10px;
  padding: 3px 5px 3px 5px;
  background: #f44336;
  color: white;
  font-size: 10px;
  line-height: 1.1;
  position: absolute;
  border-radius: 20px;
}

/* Sales section */

.col-item {
  height: 100%;
  padding: 0 15px;
  min-width: 250px;
}

.col-item .title {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
}

.col-item .menu-products {
  display: flex;
  align-items: start;
  justify-content: end;
}

.col-item .image {
  height: 180px;
  width: 170px;
}

.col-item .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #fff !important;
  border-radius: 5px;
}

.col-item .sub-title {
  font-weight: normal;
  line-height: 1.2;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
  font-size: 12px;
  font-family: "Poppins" !important;
}

.col-item .price-details {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.2;
  padding-top: 5px;
  padding-inline: 5px;
}

.col-item .old-price {
  font-weight: normal;
  position: relative;
}

.col-item .new-price {
  font-weight: bold;
  color: #cb0000;
}

.col-item .discount {
  font-weight: normal;
}

@media (max-width: 768px) {
  .menu-header .left-side {
    background: #f3f4f6;
  }

  .icon-absolute {
    right: -5px;
    bottom: -5px;
  }
}
