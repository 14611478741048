.google-review {
  background: #ffffff;
  box-shadow: 0px 1.57532px 4.72595px rgba(16, 24, 40, 0.06);
  border-radius: 4.72595px;
  display: flex;
  justify-content: end;
}

.google-review-child {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13.6867px 20.5301px;
  gap: 10.27px;
}

.google-review .right-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6.84px;
}

.google-review .right-section .title {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  color: #6a6a6a;
}

.google-review .right-section .stars {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 13.69px;
}

.google-review .right-section .value-number {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 21px;
  text-align: center;
  color: #fea500;
}

.google-review .right-section .description {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  color: #868686;
}
