.card-number {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 12px;
  height: 50px;
  background: white;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

input ::placeholder {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #77787d;
}

.checkout-label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14.88px;
  line-height: 145%;
  color: #30313d;
  flex-grow: 0;
}
