.image-details {
  height: 220px;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  background: white;
}

.product-title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-description {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  text-transform: uppercase;
}

.breadcumb {
  padding: 40px 10px;
  text-transform: uppercase;
  font-size: 16px;
}

.success-message {
  font-size: 14px;
  background-color: #4caf50;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.success-message span {
  font-weight: 700;
  margin-top: 5px;
}

.error-message {
  font-size: 14px;
  background-color: #e14848;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.error-message span {
  font-weight: 700;
}
